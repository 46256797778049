// This holds Cookie Consent settings and Google Analytics settings. Uncomment if you need them.
import './ext';

document.addEventListener('DOMContentLoaded', function() {
    // Preload fonts
    const fonts = [
        { href: 'fonts/Byrd-ExtraBold.woff2', type: 'font/woff2' },
        { href: 'fonts/Byrd-ExtraBold.woff', type: 'font/woff' },
        { href: 'fonts/Byrd-Light.woff2', type: 'font/woff2' },
        { href: 'fonts/Byrd-Light.woff', type: 'font/woff' }
    ];

    fonts.forEach(font => {
        const preloadLinkFont = document.createElement('link');
        preloadLinkFont.rel = 'preload';
        preloadLinkFont.as = 'font';
        preloadLinkFont.href = font.href;
        preloadLinkFont.crossOrigin = 'anonymous';
        document.head.appendChild(preloadLinkFont);
    });

    // Preload video by creating an invisible video element
    const video = document.createElement('video');
    video.src = 'why/why.webm';
    video.muted = true;
    video.style.display = 'none'; // Hide the video element
    document.body.appendChild(video);

    // Start loading the video
    video.load();
});
