import './config/i18n';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import settings from './config/settings';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Dynamisch importierte Komponenten
const NavBarDesktop = lazy(() => import('./components/NavBarDesktop'));
const NavBarMobile = lazy(() => import('./components/NavBarMobile'));
const Why = lazy(() => import('./components/Why'));
const Statement = lazy(() => import('./components/Statement'));
const Problem = lazy(() => import('./components/Problem'));
const Mission = lazy(() => import('./components/Mission'));
const References = lazy(() => import('./components/References'));
const How = lazy(() => import('./components/How'));
const Library = lazy(() => import('./components/Library'));
const Tools = lazy(() => import('./components/Tools'));
const Who = lazy(() => import('./components/Who'));
const WriteUs = lazy(() => import('./components/WriteUs'));
const Footer = lazy(() => import('./components/Footer'));
const Imprint = lazy(() => import('./components/Imprint'));
const Privacy = lazy(() => import('./components/Privacy'));
const NotFound = lazy(() => import('./components/NotFound'));
const ScrollToAnchor = lazy(() => import('./components/ScrollToAnchor'));
const ScrollToTopArrow = lazy(() => import('./components/ScrollToTopArrow'));

function Head() {
    const { t } = useTranslation(); 
  
    return (
        <Helmet>
            <meta name="description" content={t('metaDescription')} />
            <title>{t('pageTitle')}</title>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="anonymous" />
            <link rel="preconnect" href="https://www.youtube-nocookie.com" crossorigin="anonymous" />
            <link rel="preconnect" href="https://s.ytimg.com" crossorigin="anonymous" />
            <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
            <meta http-equiv="Permissions-Policy" content="accelerometer=*, magnetometer=*, gyroscope=*, picture-in-picture=*"></meta>
        </Helmet>
    );
}


function App() {
    const { t } = useTranslation();

    {/* Circles do not appear on mobile devices */}
    const [isMobile, setIsMobile] = useState(window.innerWidth < settings.geometry.breakpoints.md);
    
    {/* Scroll to the top arrow only on large desktops */}
    const [isLargeDesktop, setIsLargeDesktop] = useState(window.innerWidth > settings.geometry.breakpoints.md);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < settings.geometry.breakpoints.md);
            setIsLargeDesktop(window.innerWidth > settings.geometry.breakpoints.xxxl);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <BrowserRouter>
            <Suspense fallback={<div>{t('loading')}</div>}>
                <ScrollToAnchor />
                <div className="canvas">
                    <Head />
                    <main>
                        <div className="spacer-s" />
                        {isMobile ? <NavBarMobile /> : <NavBarDesktop />}
                        <div className="spacer-s" />
                        <Routes>
                            <Route index element={
                                <Suspense fallback={<div>{t('loading')}</div>}>
                                    <Why />
                                    <div className="spacer-2xl" />
                                    <Statement />
                                    <div className="spacer-2xl" />
                                    <Problem />
                                    <div className="spacer-2xl" />
                                    <Mission />
                                    <References />
                                    <div className="spacer-2xl" />
                                    <How />
                                    <div className="spacer-2xl" />
                                    <Library />
                                    <div className="spacer-2xl" />
                                    <Who />
                                    <div className="spacer-2xl" />
                                    <Tools />
                                    <div className="spacer-2xl" />
                                    <WriteUs />
                                    <Footer />
                                </Suspense>
                            } />
                            <Route path="/imprint" element={<Imprint />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        {isLargeDesktop ? <ScrollToTopArrow /> : ''}
                    </main>
                </div>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;