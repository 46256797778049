import { getCSSVariableValue } from '../utils/getCSSVariableValue.js';

const createCTAEntry = (ctaType) => ({
    circleColor: getCSSVariableValue(`--fc-${ctaType}`),
    arrowColor: getCSSVariableValue(`--bc-${ctaType}`)
});

const commonHeight = 50;
const logosPath = 'logos/';
const logos = [
    { src:  '1.png', width: 262, height: commonHeight },
    { src:  '2.png', width: 72,  height: commonHeight },
    { src:  '3.png', width: 232, height: commonHeight },
    { src:  '4.png', width: 159, height: commonHeight },
    { src:  '5.png', width: 565, height: commonHeight },
    { src:  '6.png', width: 48,  height: commonHeight },
    { src:  '7.png', width: 140, height: commonHeight },
    { src:  '8.png', width: 71,  height: commonHeight },
    { src:  '9.png', width: 70,  height: commonHeight },
    { src: '10.png', width: 229, height: commonHeight },
    { src: '11.png', width: 50,  height: commonHeight },
    { src: '12.png', width: 229, height: commonHeight },
    { src: '13.png', width: 211, height: commonHeight },
    { src: '14.png', width: 214, height: commonHeight },
    { src: '15.png', width: 173, height: commonHeight },
    { src: '16.png', width: 178, height: commonHeight },
    { src: '17.png', width: 124, height: commonHeight },
    { src: '18.png', width: 227, height: commonHeight },
    { src: '19.png', width: 180, height: commonHeight }
].map(logo => ({ ...logo, src: logosPath + logo.src }));

const settings = {
    agb: {
        basePath: '/agb',
        prefix: 'scalamento_gmbh_',
        generalTermsFileName: {
            en: 'terms-and-conditions.pdf',
            de: 'agb.pdf',
            fr: 'conditions-generales-de-vente.pdf',
        },
        salesTermsFileName: {
            en: 'terms-and-conditions.pdf',
            de: 'agb_verkauf.pdf',
            fr: 'conditions-generales-de-vente.pdf',
        },
    },
    apiKey: import.meta.env.VITE_BETAOS_API_KEY,
    apiUrl: import.meta.env.VITE_BETAOS_BASE_API_URL,
    cookiebotId: import.meta.env.VITE_COOKIEBOT_ID,
    CTA: {
        how: createCTAEntry('how'),
        library: createCTAEntry('library'),
        mission: createCTAEntry('mission'),
        problem: createCTAEntry('problem'),
        tools: createCTAEntry('tools'),
        who: createCTAEntry('who')
    },
    writeUs: {
        mailTo: 'stefan.link@scalamento.de',
        fromAddress: 'info@scalamento.de',
        fromName: 'Scalamento Website'
    },
    geometry: {
        breakpoints: {
            xs: 320,
            tiny: 480,
            sm: 576,
            minMd: 640,
            md: 768,
            lg: 992,
            maxLg: 1024,
            xl: 1200,
            xxl: 1400,
            xxxl: 1530
        }
    },
    googleAnalyticsId: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
    how: {
        numberOfSteps: 2,
    },
    libraryResourceSlider: {
        AmazonBaseUrl: "https://www.amazon.de/dp/",
        AmazonTrackingId: "scalamento01-21",
        DeezerBaseUrl: "https://widget.deezer.com/widget/dark/episode/",
        filterOptions: {
            levels: [
                { key: 'Beginner', i18nKey: 'Library.Filters.Levels.Beginner' },
                { key: 'Intermediate', i18nKey: 'Library.Filters.Levels.Intermediate' },
                { key: 'Expert', i18nKey: 'Library.Filters.Levels.Expert' },
            ],
        },
        scrollFactor: 0.4,
        WhitepaperBaseUrl: "https://www.scalamento.de/resources/content/",
        YouTubeBaseUrl: "https://www.youtube-nocookie.com/embed/",
    },
    linkedInUrl: 'https://www.linkedin.com/company/12950627/',
    logos,
    
    missionTilesSlider: {
        scrollSpeed: 2,
        tiles: 7,
    },
    navBar: {
        scrollOffset: 110,
        sections: ['why', 'statement', 'problem', 'mission', 'references', 'how', 'library', 'who', 'tools', 'writeus'],
        navSections: ['problem', 'mission', 'how', 'library', 'who', 'tools'],
        languages: ['de', 'en', 'fr'],
    },
    problem: {
        numberOfProblems: 3,
    },
    reCaptcha: {
        siteKey: import.meta.env.VITE_BETAOS_RECAPTCHA_SITE_KEY
    },
    references: {
        interval: 10000,
        totalQuotes: 10,
    },
    referencesLogosSlider: {
        numberOfLogos: 19,
    },
    scrollToAnchor: {
        yOffset: 80,
    },
    statement: {
        letterAppearanceSpeed: 50,
        letterAppearanceSpeedSlow: 200,
        numberOfIssues: 9,
        pauseBetweenIssues: 2000,
        startIssueIndex: 1,
    },
    teamMemberSlider: {
        numberOfMembers: 8,
        scrollFactor: 0.3,
    },
    tools: {
        numberOfTools: 3,
    },
};

export default settings;